import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';

@Component({
	selector: 'hk-vl-select',
	templateUrl: './hk-vl-select.component.html',
	styleUrls: ['./hk-vl-select.component.scss'],
})
export class HkVlSelectComponent implements OnChanges {
	@ViewChild('selectCustom', { static: true })
	selectCustom!: ElementRef<HTMLDivElement>;

	@HostListener('window:click', ['$event']) windonOnclick($event: any) {
		if (this.open && this.selectCustom?.nativeElement && !this.selectCustom.nativeElement.contains($event.composedPath()[0])) {
			this.toggleBoxOptions();
		}
	}

	@Input() open: boolean = false;
	@Input() value: string | number = '';
	@Input() options: Array<{
		label: string | number;
		value: string | number;
		disabled?: boolean;
		item?: any;
	}> = [];

	labels: { [key: string]: string | number } = {};

	@Input() customMsgNotValue: string = 'Seleccionar';
	@Input() customMsgNotOptions: string | undefined;
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@Input() colorbgHoverSelect: string = '#eff1fd';
	@Input() colorTextHoverSelect: string = 'var(--black-2)';

	coordenate = { x: 0, y: 0 };

	constructor() {}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['value']?.currentValue !== changes['value']?.previousValue) {
			this.buildLabels();
		}
		if (changes['options']?.currentValue !== changes['options']?.previousValue) {
			this.buildLabels();
		}
	}
	toggleBoxOptions() {
		const newValue = !this.open;

		if (newValue) {
			const coordenateSelect = this.selectCustom.nativeElement.getBoundingClientRect();
			const hookContainer = document.documentElement;
			const boxOptions = this.options.length * 43 > 240 ? 240 : this.options.length * 43;

			if (coordenateSelect.y + boxOptions < hookContainer?.getBoundingClientRect().height + hookContainer?.getBoundingClientRect().y) {
				this.coordenate.y = 48;
			} else {
				this.coordenate.y = -boxOptions - 30;
			}
		}

		this.open = newValue;

		if (!this.open) this.onChange.emit(this.value);
	}

	toggleOptions(option: { label: string | number; value: string | number; disabled?: boolean; item?: any }) {
		this.value = option.value;
		this.toggleBoxOptions();
	}

	buildLabels() {
		this.labels = {};
		this.options.forEach((option) => {
			this.labels[option.value] = option.label;
		});
	}
}
