import {
	Component,
	OnDestroy,
	OnChanges,
	AfterViewInit,
	SimpleChanges,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	ElementRef,
	HostListener,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs';
import { vlDataSource } from '../../data-sources/vl-data-source';

@Component({
	selector: 'vl-datalist',
	templateUrl: './vl-datalist.component.html',
	styleUrls: ['./vl-datalist.component.scss'],
})
export class VlDatalistComponent implements AfterViewInit, OnDestroy, OnChanges {
	@ViewChild('dataList', { static: true })
	dataList!: ElementRef<HTMLDivElement>;

	@HostListener('window:click', ['$event']) windonOnclick($event: any) {
		if (this.open && this.dataList?.nativeElement && !this.dataList.nativeElement.contains($event.composedPath()[0])) {
			this.toggleBoxOptions();
		}
	}

	@Input() open: boolean = false;
	@Input() isMulti: boolean = false;
	@Input() value: string | string[] = '';
	@Input() options!: vlDataSource<any>;

	labels: { [key: string]: string | number } = {};

	@Input() customMsgNotValue: string = 'Seleccionar';
	@Input() customMsgNotOptions: string | undefined = 'No hay opciones disponibles';
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@Input() fnSearch: (search: string) => void = (search: string) => {};
	private destroy$: Subject<boolean> = new Subject();

	_value: string[] = [];
	public search: FormControl = new FormControl();

	coordenate = { x: 0, y: 0 };

	constructor() {
		this.setValueInternal();
	}
	ngAfterViewInit(): void {
		this.search.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000), distinctUntilChanged()).subscribe((value) => {
			if (value !== this.options.filters.controls['search'].value) this.options.searchDataWith(value);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['customMsgNotValue']?.currentValue !== changes['customMsgNotValue']?.previousValue) {
			if (!this.customMsgNotValue) this.customMsgNotValue = 'Seleccionar';
		}
		if (changes['customMsgNotOptions']?.currentValue !== changes['customMsgNotOptions']?.previousValue) {
			if (!this.customMsgNotOptions) this.customMsgNotOptions = 'No hay opciones disponibles';
		}
		if (changes['value']?.currentValue !== changes['value']?.previousValue) {
			this.setValueInternal();
			this.buildLabels();
		}
		if (changes['options']?.currentValue !== changes['options']?.previousValue) {
			this.buildLabels();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	setValueInternal() {
		if (Array.isArray(this.value)) {
			this._value = [...this.value].filter((item) => item !== '' && item !== undefined && item !== null);
		} else {
			this._value = [this.value].filter((item) => item !== '' && item !== undefined && item !== null);
		}
	}

	toggleBoxOptions() {
		const newValue = !this.open;

		if (newValue) {
			const coordenateSelect = this.dataList.nativeElement.getBoundingClientRect();
			const hookContainer = document.documentElement;
			const boxOptions = this.options.listResource.length * 43 > 240 ? 240 : this.options.listResource.length * 43;

			if (coordenateSelect.y + boxOptions < hookContainer?.getBoundingClientRect().height + hookContainer?.getBoundingClientRect().y) {
				this.coordenate.y = 48;
			} else {
				this.coordenate.y = -boxOptions - 30;
			}
		}

		this.open = newValue;

		if (!this.open) {
			this.onChange.emit(this.isMulti ? this._value : this._value[0]);
			this.search.setValue('');
		}
	}

	toggleOptions(option: optionSelectVl<any>) {
		if (this.isMulti) {
			this._value.some((item) => item === option.value.toString())
				? (this._value = this._value.filter((item) => item !== option.value.toString()))
				: this._value.push(option.value.toString());
		} else {
			this._value = [option.value.toString()];
		}

		if (this.isMulti) {
			this.onChange.emit(this.isMulti ? this._value : this._value[0]);
		} else {
			this.toggleBoxOptions();
		}
	}

	buildLabels() {
		this.labels = {};
		this.options.listResource.forEach((option) => {
			this.labels[option.value] = option.label;
		});
	}
}
