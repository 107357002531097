import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlInputUploadImgComponent } from './vl-input-upload-img.component';



@NgModule({
  declarations: [
    VlInputUploadImgComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    VlInputUploadImgComponent
  ]
})
export class VlInputUploadImgModule { }
