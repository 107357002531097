import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'vl-form-group-s1',
	templateUrl: './vl-form-group-s1.component.html',
	styleUrls: ['./vl-form-group-s1.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: VlFormGroupS1Component,
			multi: true,
		},
	],
})
export class VlFormGroupS1Component implements OnInit, OnChanges, ControlValueAccessor {
	@Input() type:
		| 'text'
		| 'tel'
		| 'number'
		| 'onlyNumber'
		| 'textarea'
		| 'select-single'
		| 'select-icon'
		| 'select-multi'
		| 'range'
		| 'check'
		| 'check-invert'
		| 'upload'
		| 'date'
		| 'range-num'
		| 'range-date'
		| 'dataList'
		| 'dataList-multi'
		| 'switch'
		| 'color'
		| 'upload-img' = 'text';

	@Input() id: string = '';
	@Input() checkMsg: string = '';
	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input() hasErrors: string | null = null;

	@Input() rangeDisable: number[] = [];

	@Input() optionsoFSelect: Array<{ value: string; label: string; disable?: boolean }> | optionSelectVl[] = [];

	@Input() optionDatalist: any = [];

	@Input() disabled: boolean = false;
	@Input() maxlength: string | number = '';
	@Input() minlength: string | number = '';
	@Input() max: string | number | Date = '';
	@Input() min: string | number | Date = '';

	@Input() fileName: string = '';

	@Input() extensions: string[] = ['image/jpeg', 'image/png', 'application/pdf', '.xlsx'];
	accept = this.extensions.join(',');
	format = 'JPEG, PNG, PDF y XLSX';
	@Input() maxSizeFile: number = 25000000;
	@Input() control: FormControl | undefined | any;
	@Input() bgColorBoxImg: string = '#ffffff';
	// @Input() value: any = '';

	multiInput: Array<string | number | undefined> = [];

	public fileTemp: any = {};
	@ViewChild('inputFile') inputFile!: ElementRef<HTMLInputElement>;

	@Output() filesOnChange: EventEmitter<any> = new EventEmitter();
	@Input() fnSearch: (search: string) => void = (search: string) => {};

	@Input() showLength: boolean = false;
	@Input() showMaxLength: boolean = false;

	value!: any;
	isDisabled!: boolean;
	onChange: (value: any) => void = () => {};
	onTouched: () => void = () => {};

	maxDateFilter = new Date();

	constructor() {}
	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {}

	writeValue(obj: any): void {
		this.value = typeof obj === 'string' ? obj.trim() : obj;

		if (['range-num', 'range-date'].includes(this.type)) {
			this.multiInput = this.value;
		}
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	checkChangeValue() {
		const ref = Boolean(this.value);
		this.value = !ref;
		this.onChange(this.value);
	}

	uploadFile($event: any) {
		const [file] = $event?.target?.files ?? [];

		if (!file) {
			this.clearRefFile();
			return;
		}

		let { type, size } = file;

		if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') type = '.xlsx';

		if (!this.extensions.includes(type) || size > this.maxSizeFile) {
			this.clearRefFile();
			return;
		}

		this.fileTemp = {
			id: this.id,
			fileRaw: file,
			name: file.name,
		};

		this.value = file.name;
		this.fileName = file.name;

		this.onChange(this.value);

		this.filesOnChange.emit(this.fileTemp);
	}

	clearRefFile() {
		this.fileTemp = {
			id: this.id,
			fileRaw: null,
			name: null,
		};
		this.value = null;
		this.fileName = '';
		if (this.inputFile?.nativeElement?.value) {
			this.inputFile.nativeElement.value = '';
		}
		this.onChange(this.value);

		this.filesOnChange.emit(this.fileTemp);
	}

	changeMultiValue(index: number, value: string | any) {
		if (['range-num', 'range-date'].includes(this.type) && !this.multiInput.every((value: any) => ['', undefined, null].includes(value))) {
			if (!this.multiInput[0]) this.multiInput[0] = undefined;
			if (!this.multiInput[1]) this.multiInput[1] = undefined;

			console.log(this.multiInput);

			this.onChange(this.multiInput);
		}
	}
}
