import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { environment } from '@environments/environment';

@Component({
	selector: 'vl-form-group-s2',
	templateUrl: './vl-form-group-s2.component.html',
	styleUrls: ['./vl-form-group-s2.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: VlFormGroupS2Component,
			multi: true,
		},
	],
})
export class VlFormGroupS2Component implements OnChanges {
	@ViewChild('inputStartDate', { static: false })
	inputStartDate!: ElementRef<HTMLInputElement>;
	@ViewChild('inputEndDate', { static: false })
	inputEndDate!: ElementRef<HTMLInputElement>;
	keys = Object.keys;

	@Input() type:
		| 'select'
		| 'yes-no'
		| 'regex'
		| 'download'
		| 'upload'
		| 'textarea'
		| 'text'
		| 'number'
		| 'email'
		| 'phone'
		| 'date'
		| 'range-date'
		| 'rif'
		| 'ci'
		| 'status-muni-vzla' = 'text';
	@Input() label: string = '';
	@Input() placeholder: string = '';

	@Input() selectOptions: Array<{
		value: string;
		label: string;
		disable?: boolean;
		item?: any;
	}> = [];

	@Input() disabled: boolean = false;
	@Input() required: boolean = false;
	@Input() mask: boolean = false;
	@Input() maxlength: string | number = '';
	@Input() minlength: string | number = '';
	@Input() max: string | number = '';
	@Input() min: string | number = '';
	@Input() customMsgValidation: string | undefined | null;
	@Input() fControl!: NgControl;

	@Input() fileName: string = '';
	@Input() download: string = '';
	@Input() entryInput: 'allChart' | 'onlyNumber' | 'onlyText' = 'allChart';

	@Input() colorCheck: string = '#ff3160';
	@Input() colorbgHoverSelect: string = '#eff1fd';
	@Input() colorTextHoverSelect: string = 'var(--black-2)';

	storageUrl: string = environment.storage + '/tk/';

	multiInput: Array<string | undefined> = [];

	public fileTemp!: {
		id?: string;
		fileRaw: File | null;
		name: string | null;
		fControl?: NgControl;
	};

	@ViewChild('inputFile') inputFile!: ElementRef<HTMLInputElement>;
	@Output() filesOnChange: EventEmitter<any> = new EventEmitter();

	// Date
	@Input() configDate: 'lt' | 'lte' | 'gt' | 'gte' | undefined | null;
	@Input() dateDiffMeasure: string | undefined | null;
	@Input() dateDiffValue: number | undefined | null;

	//Format year-moth-day
	maxDate: string | undefined;
	minDate: string | undefined;

	//Range-Date
	@Input() configRangeDateStart: 'lt' | 'lte' | 'gt' | 'gte' | undefined | null;
	@Input() startDateDiffMeasure: string | undefined | null;
	@Input() startDateDiffValue: number | undefined | null;

	@Input() configRangeDateEnd: 'lt' | 'lte' | 'gt' | 'gte' | undefined | null;
	@Input() endDateDiffMeasure: string | undefined | null;
	@Input() endDateDiffValue: number | undefined | null;

	// Download upload

	@Input() extensions: string[] = ['image/jpeg', 'image/png', 'application/pdf', '.xlsx'];

	accept = this.extensions.join(',');
	format = 'JPEG, PNG, PDF y XLSX';
	@Input() maxSizeFile: number = 25000000;

	maxDateStart: string | undefined;
	minDateStart: string | undefined;
	maxDateEnd: string | undefined;
	minDateEnd: string | undefined;

	// Estados y municipios
	statesOfVnzla: { label: string; value: string }[] = [
		{ label: 'Amazonas', value: 'Amazonas' },
		{ label: 'Anzoátegui', value: 'Anzoátegui' },
		{ label: 'Apure', value: 'Apure' },
		{ label: 'Aragua', value: 'Aragua' },
		{ label: 'Barinas', value: 'Barinas' },
		{ label: 'Bolívar', value: 'Bolívar' },
		{ label: 'Carabobo', value: 'Carabobo' },
		{ label: 'Cojedes', value: 'Cojedes' },
		{ label: 'Delta Amacuro', value: 'Delta Amacuro' },
		{ label: 'Distrito Capital', value: 'Distrito Capital' },
		{ label: 'Falcón', value: 'Falcón' },
		{ label: 'Guárico', value: 'Guárico' },
		{ label: 'Lara', value: 'Lara' },
		{ label: 'La Guaira', value: 'La Guaira' },
		{ label: 'Mérida', value: 'Mérida' },
		{ label: 'Miranda', value: 'Miranda' },
		{ label: 'Monagas', value: 'Monagas' },
		{ label: 'Nueva Esparta', value: 'Nueva Esparta' },
		{ label: 'Portuguesa', value: 'Portuguesa' },
		{ label: 'Sucre', value: 'Sucre' },
		{ label: 'Táchira', value: 'Táchira' },
		{ label: 'Trujillo', value: 'Trujillo' },
		{ label: 'Yaracuy', value: 'Yaracuy' },
		{ label: 'Zulia', value: 'Zulia' },
	];
	muniOfVnzla: {
		[key: string]: { label: string; value: string }[] | undefined;
	} = {
		Amazonas: [
			{ label: 'Alto Orinoco', value: 'Alto Orinoco' },
			{ label: 'Atabapo', value: 'Atabapo' },
			{ label: 'Atures', value: 'Atures' },
			{ label: 'Autana', value: 'Autana' },
			{ label: 'Manapiare', value: 'Manapiare' },
			{ label: 'Maroa', value: 'Maroa' },
			{ label: 'Río Negro', value: 'Río Negro' },
		],
		Anzoátegui: [
			{ label: 'Anaco', value: 'Anaco' },
			{ label: 'Aragua', value: 'Aragua' },
			{ label: 'Bolívar', value: 'Bolívar' },
			{ label: 'Bruzual', value: 'Bruzual' },
			{ label: 'Cajigal', value: 'Cajigal' },
			{ label: 'Carvajal', value: 'Carvajal' },
			{ label: 'Freites', value: 'Freites' },
			{ label: 'Guanipa', value: 'Guanipa' },
			{ label: 'Guanta', value: 'Guanta' },
			{ label: 'Independencia', value: 'Independencia' },
			{ label: 'Libertad', value: 'Libertad' },
			{ label: 'Sir Arthur McGregor', value: 'Sir Arthur McGregor' },
			{ label: 'Miranda', value: 'Miranda' },
			{ label: 'Monagas', value: 'Monagas' },
			{ label: 'Peñalver', value: 'Peñalver' },
			{ label: 'Píritu', value: 'Píritu' },
			{ label: 'San Juan de Capistrano', value: 'San Juan de Capistrano' },
			{ label: 'Santa Ana', value: 'Santa Ana' },
			{ label: 'Simón Rodríguez', value: 'Simón Rodríguez' },
			{ label: 'Sotillo', value: 'Sotillo' },
			{
				label: 'Turístico Diego Bautista Urbaneja',
				value: 'Turístico Diego Bautista Urbaneja',
			},
		],
		Apure: [
			{ label: 'Achaguas', value: 'Achaguas' },
			{ label: 'Biruaca', value: 'Biruaca' },
			{ label: 'Pedro Camejo', value: 'Pedro Camejo' },
			{ label: 'Muñoz', value: 'Muñoz' },
			{ label: 'Páez', value: 'Páez' },
			{ label: 'Rómulo Gallegos', value: 'Rómulo Gallegos' },
			{ label: 'San Fernando', value: 'San Fernando' },
		],
		Aragua: [
			{ label: 'Alcántara', value: 'Alcántara' },
			{ label: 'Bolívar', value: 'Bolívar' },
			{ label: 'Camatagua', value: 'Camatagua' },
			{ label: 'Girardot', value: 'Girardot' },
			{ label: 'Iragorry', value: 'Iragorry' },
			{ label: 'Lamas', value: 'Lamas' },
			{ label: 'Libertador', value: 'Libertador' },
			{ label: 'Mariño', value: 'Mariño' },
			{ label: 'Michelena', value: 'Michelena' },
			{
				label: 'Ocumare de la Costa de Oro',
				value: 'Ocumare de la Costa de Oro',
			},
			{ label: 'Revenga', value: 'Revenga' },
			{ label: 'Ribas', value: 'Ribas' },
			{ label: 'San Casimiro', value: 'San Casimiro' },
			{ label: 'San Sebastián', value: 'San Sebastián' },
			{ label: 'Sucre', value: 'Sucre' },
			{ label: 'Tovar', value: 'Tovar' },
			{ label: 'Urdaneta', value: 'Urdaneta' },
			{ label: 'Zamora', value: 'Zamora' },
		],
		Barinas: [
			{ label: 'Alberto Arvelo Torrealba', value: 'Alberto Arvelo Torrealba' },
			{ label: 'Andrés Eloy Blanco', value: 'Andrés Eloy Blanco' },
			{ label: 'Antonio José de Sucre', value: 'Antonio José de Sucre' },
			{ label: 'Arismendi', value: 'Arismendi' },
			{ label: 'Barinas', value: 'Barinas' },
			{ label: 'Bolívar', value: 'Bolívar' },
			{ label: 'Cruz Paredes', value: 'Cruz Paredes' },
			{ label: 'Ezequiel Zamora', value: 'Ezequiel Zamora' },
			{ label: 'Obispos', value: 'Obispos' },
			{ label: 'Pedraza', value: 'Pedraza' },
			{ label: 'Rojas', value: 'Rojas' },
			{ label: 'Sosa', value: 'Sosa' },
		],
		Bolívar: [
			{
				label: 'Angostura',
				value: 'Angostura',
			},
			{
				label: 'Angostura del Orinoco',
				value: 'Angostura del Orinoco',
			},
			{
				label: 'Caroní',
				value: 'Caroní',
			},
			{
				label: 'Cedeño',
				value: 'Cedeño',
			},
			{
				label: 'Chien',
				value: 'Chien',
			},
			{
				label: 'El Callao',
				value: 'El Callao',
			},
			{
				label: 'Gran Sabana',
				value: 'Gran Sabana',
			},
			{
				label: 'Piar',
				value: 'Piar',
			},
			{
				label: 'Roscio',
				value: 'Roscio',
			},
			{
				label: 'Sifontes',
				value: 'Sifontes',
			},
			{
				label: 'Sucre',
				value: 'Sucre',
			},
		],
		Carabobo: [
			{ label: 'Bejuma', value: 'Bejuma' },
			{ label: 'Carlos Arvelo', value: 'Carlos Arvelo' },
			{ label: 'Diego Ibarra', value: 'Diego Ibarra' },
			{ label: 'Guacara', value: 'Guacara' },
			{ label: 'Juan José Mora', value: 'Juan José Mora' },
			{ label: 'Libertador', value: 'Libertador' },
			{ label: 'Los Guayos', value: 'Los Guayos' },
			{ label: 'Miranda', value: 'Miranda' },
			{ label: 'Montalbán', value: 'Montalbán' },
			{ label: 'Naguanagua', value: 'Naguanagua' },
			{ label: 'Puerto Cabello', value: 'Puerto Cabello' },
			{ label: 'San Diego', value: 'San Diego' },
			{ label: 'San Joaquín', value: 'San Joaquín' },
			{ label: 'Valencia', value: 'Valencia' },
		],
		Cojedes: [
			{ label: 'Anzoátegui', value: 'Anzoátegui' },
			{ label: 'San Carlos', value: 'San Carlos' },
			{ label: 'Girardot', value: 'Girardot' },
			{ label: 'Lima Blanco', value: 'Lima Blanco' },
			{ label: 'Pao de San Juan Bautista', value: 'Pao de San Juan Bautista' },
			{ label: 'Ricaurte', value: 'Ricaurte' },
			{ label: 'Rómulo Gallegos', value: 'Rómulo Gallegos' },
			{ label: 'Tinaco', value: 'Tinaco' },
			{ label: 'Tinaquillo', value: 'Tinaquillo' },
		],
		'Delta Amacuro': [
			{ label: 'Antonio Díaz', value: 'Antonio Díaz' },
			{ label: 'Casacoima', value: 'Casacoima' },
			{ label: 'Pedernales', value: 'Pedernales' },
			{ label: 'Tucupita', value: 'Tucupita' },
		],
		'Distrito Capital': [{ label: 'Libertador', value: 'Libertador' }],

		Falcón: [
			{ label: 'Acosta', value: 'Acosta' },
			{ label: 'Bolívar', value: 'Bolívar' },
			{ label: 'Buchivacoa', value: 'Buchivacoa' },
			{ label: 'Carirubana', value: 'Carirubana' },
			{ label: 'Colina', value: 'Colina' },
			{ label: 'Dabajuro', value: 'Dabajuro' },
			{ label: 'Democracia', value: 'Democracia' },
			{ label: 'Falcón', value: 'Falcón' },
			{ label: 'Federación', value: 'Federación' },
			{ label: 'Iturriza', value: 'Iturriza' },
			{ label: 'Jacura', value: 'Jacura' },
			{ label: 'Los Taques', value: 'Los Taques' },
			{ label: 'Manaure', value: 'Manaure' },
			{ label: 'Mauroa', value: 'Mauroa' },
			{ label: 'Miranda', value: 'Miranda' },
			{ label: 'Palmasola', value: 'Palmasola' },
			{ label: 'Petit', value: 'Petit' },
			{ label: 'Píritu', value: 'Píritu' },
			{ label: 'San Francisco', value: 'San Francisco' },
			{ label: 'Sucre', value: 'Sucre' },
			{ label: 'Silva', value: 'Silva' },
			{ label: 'Tocópero', value: 'Tocópero' },
			{ label: 'Unión', value: 'Unión' },
			{ label: 'Urumaco', value: 'Urumaco' },
			{ label: 'Zamora', value: 'Zamora' },
		],
		Guárico: [
			{ label: 'Camaguán', value: 'Camaguán' },
			{ label: 'Chaguaramas', value: 'Chaguaramas' },
			{ label: 'El Socorro', value: 'El Socorro' },
			{ label: 'Francisco de Miranda', value: 'Francisco de Miranda' },
			{ label: 'José Félix Ribas', value: 'José Félix Ribas' },
			{ label: 'José Tadeo Monagas ', value: 'José Tadeo Monagas ' },
			{ label: 'Juan Germán Roscio', value: 'Juan Germán Roscio' },
			{ label: 'Juan José Rondón', value: 'Juan José Rondón' },
			{ label: 'Julián Mellado', value: 'Julián Mellado' },
			{ label: 'Leonardo Infante', value: 'Leonardo Infante' },
			{ label: 'Ortiz', value: 'Ortiz' },
			{ label: 'San Gerónimo de Guayabal', value: 'San Gerónimo de Guayabal' },
			{ label: 'San José de Guaribe', value: 'San José de Guaribe' },
			{ label: 'Santa María de Ipire', value: 'Santa María de Ipire' },
			{ label: 'Zaraza', value: 'Zaraza' },
		],
		Lara: [
			{ label: 'Andrés Eloy Blanco', value: 'Andrés Eloy Blanco' },
			{ label: 'Crespo', value: 'Crespo' },
			{ label: 'Iribarren', value: 'Iribarren' },
			{ label: 'Jiménez', value: 'Jiménez' },
			{ label: 'Morán', value: 'Morán' },
			{ label: 'Palavecino', value: 'Palavecino' },
			{ label: 'Simón Planas', value: 'Simón Planas' },
			{ label: 'Torres', value: 'Torres' },
			{ label: 'Urdaneta', value: 'Urdaneta' },
		],
		'La Guaira': [{ label: 'Vargas', value: 'Vargas' }],
		Mérida: [
			{ label: 'Alberto Adriani', value: 'Alberto Adriani' },
			{ label: 'Andrés Bello', value: 'Andrés Bello' },
			{ label: 'Antonio Pinto Salinas', value: 'Antonio Pinto Salinas' },
			{ label: 'Aricagua', value: 'Aricagua' },
			{ label: 'Arzobispo Chacón', value: 'Arzobispo Chacón' },
			{ label: 'Campo Elías', value: 'Campo Elías' },
			{ label: 'Caracciolo Parra Olmedo', value: 'Caracciolo Parra Olmedo' },
			{ label: 'Cardenal Quintero', value: 'Cardenal Quintero' },
			{ label: 'Guaraque', value: 'Guaraque' },
			{ label: 'Julio Cesar Salas', value: 'Julio Cesar Salas' },
			{ label: 'Justo Briceño', value: 'Justo Briceño' },
			{ label: 'Libertador', value: 'Libertador' },
			{ label: 'Miranda', value: 'Miranda' },
			{ label: 'Obispo Ramos de Lora', value: 'Obispo Ramos de Lora' },
			{ label: 'Padre Noguera', value: 'Padre Noguera' },
			{ label: 'Pueblo Llano', value: 'Pueblo Llano' },
			{ label: 'Rangel', value: 'Rangel' },
			{ label: 'Rivas Dávila', value: 'Rivas Dávila' },
			{ label: 'Santos Marquina', value: 'Santos Marquina' },
			{ label: 'Sucre', value: 'Sucre' },
			{ label: 'Tovar', value: 'Tovar' },
			{ label: 'Tulio Febres Cordero', value: 'Tulio Febres Cordero' },
			{ label: 'Zea', value: 'Zea' },
		],
		Miranda: [
			{ label: 'Acevedo', value: 'Acevedo' },
			{ label: 'Andrés Bello', value: 'Andrés Bello' },
			{ label: 'Baruta', value: 'Baruta' },
			{ label: 'Bolívar', value: 'Bolívar' },
			{ label: 'Brión', value: 'Brión' },
			{ label: 'Buroz', value: 'Buroz' },
			{ label: 'Carrizal', value: 'Carrizal' },
			{ label: 'Chacao', value: 'Chacao' },
			{ label: 'Cristóbal Rojas', value: 'Cristóbal Rojas' },
			{ label: 'El Hatillo', value: 'El Hatillo' },
			{ label: 'Guaicaipuro', value: 'Guaicaipuro' },
			{ label: 'Gual', value: 'Gual' },
			{ label: 'Independencia', value: 'Independencia' },
			{ label: 'Lander', value: 'Lander' },
			{ label: 'Los Salias', value: 'Los Salias' },
			{ label: 'Páez', value: 'Páez' },
			{ label: 'Paz Castillo', value: 'Paz Castillo' },
			{ label: 'Plaza', value: 'Plaza' },
			{ label: 'Sucre', value: 'Sucre' },
			{ label: 'Urdaneta', value: 'Urdaneta' },
			{ label: 'Zamora', value: 'Zamora' },
		],
		Monagas: [
			{ label: 'Acosta', value: 'Acosta' },
			{ label: 'Aguasay', value: 'Aguasay' },
			{ label: 'Bolívar', value: 'Bolívar' },
			{ label: 'Caripe', value: 'Caripe' },
			{ label: 'Cedeño', value: 'Cedeño' },
			{ label: 'Libertador', value: 'Libertador' },
			{ label: 'Maturín', value: 'Maturín' },
			{ label: 'Piar', value: 'Piar' },
			{ label: 'Punceres', value: 'Punceres' },
			{ label: 'Santa Bárbara', value: 'Santa Bárbara' },
			{ label: 'Sotillo', value: 'Sotillo' },
			{ label: 'Uracoa', value: 'Uracoa' },
			{ label: 'Zamora', value: 'Zamora' },
		],
		'Nueva Esparta': [
			{ label: 'Antolín del Campo', value: 'Antolín del Campo' },
			{ label: 'Antonio Díaz', value: 'Antonio Díaz' },
			{ label: 'Arismendi', value: 'Arismendi' },
			{ label: 'García', value: 'García' },
			{ label: 'Gómez', value: 'Gómez' },
			{ label: 'Macanao', value: 'Macanao' },
			{ label: 'Maneiro', value: 'Maneiro' },
			{ label: 'Marcano', value: 'Marcano' },
			{ label: 'Mariño', value: 'Mariño' },
			{ label: 'Tubores', value: 'Tubores' },
		],
		Portuguesa: [
			{ label: 'Agua Blanca', value: 'Agua Blanca' },
			{ label: 'Araure', value: 'Araure' },
			{ label: 'Esteller', value: 'Esteller' },
			{ label: 'Guanare', value: 'Guanare' },
			{ label: 'Guanarito', value: 'Guanarito' },
			{ label: 'José Vicente de Unda', value: 'José Vicente de Unda' },
			{ label: 'Ospino', value: 'Ospino' },
			{ label: 'Páez', value: 'Páez' },
			{ label: 'Papelón', value: 'Papelón' },
			{ label: 'San Genaro de Boconoíto', value: 'San Genaro de Boconoíto' },
			{ label: 'San Rafael de Onoto', value: 'San Rafael de Onoto' },
			{ label: 'Santa Rosalía', value: 'Santa Rosalía' },
			{ label: 'Sucre', value: 'Sucre' },
			{ label: 'Turén', value: 'Turén' },
		],
		Sucre: [
			{ label: 'Andrés Eloy Blanco', value: 'Andrés Eloy Blanco' },
			{ label: 'Andrés Mata', value: 'Andrés Mata' },
			{ label: 'Arismendi', value: 'Arismendi' },
			{ label: 'Benítez', value: 'Benítez' },
			{ label: 'Bermúdez', value: 'Bermúdez' },
			{ label: 'Bolívar', value: 'Bolívar' },
			{ label: 'Cajigal', value: 'Cajigal' },
			{ label: 'Cruz Salmerón Acosta', value: 'Cruz Salmerón Acosta' },
			{ label: 'Libertador', value: 'Libertador' },
			{ label: 'Mariño', value: 'Mariño' },
			{ label: 'Mejía', value: 'Mejía' },
			{ label: 'Montes', value: 'Montes' },
			{ label: 'Ribero', value: 'Ribero' },
			{ label: 'Sucre', value: 'Sucre' },
			{ label: 'Valdez', value: 'Valdez' },
		],
		Táchira: [
			{ label: 'Andrés Bello', value: 'Andrés Bello' },
			{ label: 'Antonio Rómulo Costa', value: 'Antonio Rómulo Costa' },
			{ label: 'Ayacucho', value: 'Ayacucho' },
			{ label: 'Bolívar', value: 'Bolívar' },
			{ label: 'Cárdenas', value: 'Cárdenas' },
			{ label: 'Córdoba', value: 'Córdoba' },
			{ label: 'Fernández', value: 'Fernández' },
			{ label: 'Francisco de Miranda', value: 'Francisco de Miranda' },
			{ label: 'García de Hevia', value: 'García de Hevia' },
			{ label: 'Guásimos', value: 'Guásimos' },
			{ label: 'Independencia', value: 'Independencia' },
			{ label: 'Jáuregui', value: 'Jáuregui' },
			{ label: 'José María Vargas', value: 'José María Vargas' },
			{ label: 'Junín', value: 'Junín' },
			{ label: 'Libertad', value: 'Libertad' },
			{ label: 'Libertador', value: 'Libertador' },
			{ label: 'Lobatera', value: 'Lobatera' },
			{ label: 'Michelena', value: 'Michelena' },
			{ label: 'Panamericano', value: 'Panamericano' },
			{ label: 'Pedro María Ureña', value: 'Pedro María Ureña' },
			{ label: 'Rafael Urdaneta', value: 'Rafael Urdaneta' },
			{ label: 'Samuel Dario Maldonado', value: 'Samuel Dario Maldonado' },
			{ label: 'San Cristóbal ', value: 'San Cristóbal ' },
			{ label: 'San Judas Tadeo', value: 'San Judas Tadeo' },
			{ label: 'Seboruco', value: 'Seboruco' },
			{ label: 'Simón Rodríguez', value: 'Simón Rodríguez' },
			{ label: 'Sucre', value: 'Sucre' },
			{ label: 'Torbes', value: 'Torbes' },
			{ label: 'Uribante', value: 'Uribante' },
		],
		Trujillo: [
			{ label: 'Andrés Bello', value: 'Andrés Bello' },
			{ label: 'Boconó', value: 'Boconó' },
			{ label: 'Bolívar', value: 'Bolívar' },
			{ label: 'Candelaria', value: 'Candelaria' },
			{ label: 'Carache', value: 'Carache' },
			{ label: 'Carvajal', value: 'Carvajal' },
			{ label: 'Escuque', value: 'Escuque' },
			{ label: 'Juan Vicente Campo Elías', value: 'Juan Vicente Campo Elías' },
			{ label: 'La Ceiba', value: 'La Ceiba' },
			{ label: 'Márquez Cañizales', value: 'Márquez Cañizales' },
			{ label: 'Miranda', value: 'Miranda' },
			{ label: 'Monte Carmelo', value: 'Monte Carmelo' },
			{ label: 'Motatán', value: 'Motatán' },
			{ label: 'Pampán', value: 'Pampán' },
			{ label: 'Pampanito', value: 'Pampanito' },
			{ label: 'Rangel', value: 'Rangel' },
			{ label: 'Sucre', value: 'Sucre' },
			{ label: 'Trujillo', value: 'Trujillo' },
			{ label: 'Urdaneta', value: 'Urdaneta' },
			{ label: 'Valera', value: 'Valera' },
		],
		Yaracuy: [
			{ label: 'Arístides Bastidas', value: 'Arístides Bastidas' },
			{ label: 'Bolívar', value: 'Bolívar' },
			{ label: 'Bruzual', value: 'Bruzual' },
			{ label: 'Cocorote', value: 'Cocorote' },
			{ label: 'Independencia', value: 'Independencia' },
			{ label: 'José Antonio Páez', value: 'José Antonio Páez' },
			{ label: 'La Trinidad', value: 'La Trinidad' },
			{ label: 'Manuel Monge', value: 'Manuel Monge' },
			{ label: 'Nirgua', value: 'Nirgua' },
			{ label: 'Peña', value: 'Peña' },
			{ label: 'San Felipe', value: 'San Felipe' },
			{ label: 'Sucre', value: 'Sucre' },
			{ label: 'Urachiche', value: 'Urachiche' },
			{ label: 'Veroes', value: 'Veroes' },
		],
		Zulia: [
			{ label: 'Almirante Padilla', value: 'Almirante Padilla' },
			{ label: 'Baralt', value: 'Baralt' },
			{ label: 'Cabimas', value: 'Cabimas' },
			{ label: 'Catatumbo', value: 'Catatumbo' },
			{ label: 'Colón', value: 'Colón' },
			{ label: 'Francisco Javier Pulgar', value: 'Francisco Javier Pulgar' },
			{ label: 'Guajira', value: 'Guajira' },
			{ label: 'Jesús Enrique Lossada', value: 'Jesús Enrique Lossada' },
			{ label: 'Jesús María Semprún', value: 'Jesús María Semprún' },
			{ label: 'La Cañada de Urdaneta', value: 'La Cañada de Urdaneta' },
			{ label: 'Lagunillas', value: 'Lagunillas' },
			{ label: 'Machiques', value: 'Machiques' },
			{ label: 'Mara', value: 'Mara' },
			{ label: 'Maracaibo', value: 'Maracaibo' },
			{ label: 'Miranda', value: 'Miranda' },
			{ label: 'Rosario de Perijá', value: 'Rosario de Perijá' },
			{ label: 'San Francisco', value: 'San Francisco' },
			{ label: 'Santa Rita', value: 'Santa Rita' },
			{ label: 'Simón Bolívar', value: 'Simón Bolívar' },
			{ label: 'Sucre', value: 'Sucre' },
			{ label: 'Valmore Rodríguez', value: 'Valmore Rodríguez' },
		],
	};

	value!: any;
	isDisabled!: boolean;
	onChange: (value: any) => void = () => {};
	onTouched: () => void = () => {};

	errorFormMsg: { [key: string]: string | null } = {
		required: `Este campo es requerido`,
		min: `Este campo puede tener como mínimo el valor ${this.min}`,
		max: `Este campo puede tener como máximo el valor ${this.max}`,
		minlength: `Este campo debe tener una longitud mínima de ${this.minlength} caracteres`,
		maxlength: `Este campo puede debe tener una longitud máxima de ${this.maxlength} caracteres`,
		pattern: 'Este campo posee un formato no válido',
		default: 'valor no válido',
	};

	constructor() {}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['type'] && changes['type'].currentValue !== changes['type'].previousValue) {
			this.errorFormMsg = {
				required: `Este campo es requerido`,
				min: `Este campo puede tener como mínimo el valor ${this.min}`,
				max: `Este campo puede tener como máximo el valor ${this.max}`,
				minlength: `Este campo debe tener una longitud mínima de ${this.minlength} caracteres`,
				maxlength: `Este campo puede debe tener una longitud máxima de ${this.maxlength} caracteres`,
				pattern: 'Este campo posee un formato no válido',
				default: 'valor no válido',
			};
		}
		if (changes['fControl'] && changes['fControl'].currentValue !== changes['fControl'].previousValue) {
		}
		if (changes['extensions'] && changes['extensions'].currentValue !== changes['extensions'].previousValue) {
			this.accept = this.extensions.join(',');

			const extensions = this.extensions.map((item) => {
				const translate: any = {
					'image/jpeg': 'JPEG',
					'image/png': 'PNG',
					'application/pdf': 'PDF',
					'.xlsx': 'XLSX',
					'text/plain': 'TXT',
				};

				return translate[item];
			});

			const lastExtensions = extensions.pop();

			this.format = extensions.length > 0 ? extensions.join(', ') + ' y ' + lastExtensions : lastExtensions;
		}
		if (changes['configDate'] && changes['configDate'].currentValue !== changes['configDate'].previousValue) {
			this.setLimitDate();
		}
		if (
			changes['configRangeDateStart'] &&
			changes['configRangeDateEnd'] &&
			(changes['configRangeDateStart'].currentValue !== changes['configRangeDateStart'].previousValue ||
				changes['configRangeDateEnd'].currentValue !== changes['configRangeDateEnd'].previousValue)
		) {
			this.setLimitRangeDate();
		}
	}
	writeValue(obj: any): void {
		this.value = typeof obj === 'string' ? obj.trim() : obj;
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}
	uploadFile($event: any) {
		const [file] = $event?.target?.files ?? [];

		if (!file) return;

		let { type, size } = file;

		if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') type = '.xlsx';

		if (!this.extensions.includes(type) || size > this.maxSizeFile) {
			this.clearRefFile();
			return;
		}

		this.fileTemp = {
			fileRaw: file,
			name: file.name,
			fControl: this.fControl,
		};

		this.value = file.name;
		this.fileName = file.name;

		this.onChange(this.value);

		this.filesOnChange.emit(this.fileTemp);
	}
	clearRefFile() {
		this.fileTemp = {
			fileRaw: null,
			name: null,
		};
		this.value = null;
		this.fileName = '';
		if (this.inputFile?.nativeElement?.value) {
			this.inputFile.nativeElement.value = '';
		}
		this.onChange(this.value);
		this.filesOnChange.emit(this.fileTemp);
	}

	changeMultiValue(index: number, value: string | any) {
		this.multiInput[index] = value;
		this.onChange(this.multiInput.join('-'));
	}

	setLimitDate() {
		if (this.type !== 'date') return;

		const currentDate = new Date();
		let method = 'Date';
		let diference = 0;

		if (this.dateDiffMeasure) {
			if (this.dateDiffMeasure === 'day') {
				method = 'Date';
			}
			if (this.dateDiffMeasure === 'month') {
				method = 'Month';
			}
			if (this.dateDiffMeasure === 'year') {
				method = 'FullYear';
			}

			if (!isNaN(Number(this.dateDiffValue))) {
				diference += Number(this.dateDiffValue);
			}
		}

		if (this.configDate === 'lt') currentDate.setDate(currentDate.getDate() - 1);

		if (this.configDate === 'gt') currentDate.setDate(currentDate.getDate() + 1);

		const currentDateString = currentDate.toISOString().split('T')[0];

		if (this.configDate === 'lt' || this.configDate === 'lte') {
			// Por defecto el minimo es hasta 12 meses antes

			const minDateForce: any = new Date();

			if (!this.dateDiffMeasure) {
				minDateForce.setFullYear(minDateForce.getFullYear() - 1);
			} else {
				minDateForce[`set${method}`](minDateForce[`get${method}`]() - diference);
			}

			this.minDate = minDateForce.toISOString().split('T')[0];
			//Fecha maxima
			this.maxDate = currentDateString;
		}

		if (this.configDate === 'gt' || this.configDate === 'gte') {
			const maxDateForce: any = new Date();

			if (!this.dateDiffMeasure) {
				maxDateForce.setFullYear(maxDateForce.getFullYear() + 1);
			} else {
				maxDateForce[`set${method}`](maxDateForce[`get${method}`]() + diference);
			}

			this.maxDate = maxDateForce.toISOString().split('T')[0];

			//Fecha minima
			this.minDate = currentDateString;
		}
	}
	setLimitRangeDate() {
		if (this.type !== 'range-date') return;

		const currentStartDate = new Date();
		let startMethod = 'Date';
		let startDiference = 0;
		let endMethod = 'Date';
		let endDiference = 0;

		if (this.startDateDiffMeasure) {
			if (this.startDateDiffMeasure === 'day') {
				startMethod = 'Date';
			}
			if (this.startDateDiffMeasure === 'month') {
				startMethod = 'Month';
			}
			if (this.startDateDiffMeasure === 'year') {
				startMethod = 'FullYear';
			}

			if (!isNaN(Number(this.startDateDiffValue))) {
				startDiference += Number(this.startDateDiffValue);
			}
		}

		if (this.endDateDiffMeasure) {
			if (this.endDateDiffMeasure === 'day') {
				endMethod = 'Date';
			}
			if (this.endDateDiffMeasure === 'month') {
				endMethod = 'Month';
			}
			if (this.endDateDiffMeasure === 'year') {
				endMethod = 'FullYear';
			}

			if (!isNaN(Number(this.endDateDiffValue))) {
				endDiference += Number(this.endDateDiffValue);
			}
		}

		if (this.configRangeDateStart === 'lt') currentStartDate.setDate(currentStartDate.getDate() - 1);

		if (this.configRangeDateStart === 'gt') currentStartDate.setDate(currentStartDate.getDate() + 1);

		const currentStartDateString = currentStartDate.toISOString().split('T')[0];

		if (this.configRangeDateStart === 'lt' || this.configRangeDateStart === 'lte') {
			// //Por defecto el minimo es hasta 12 meses antes
			// const minDateForce = new Date()
			// minDateForce.setFullYear(minDateForce.getFullYear() - 1)
			// this.minDateStart = minDateForce.toISOString().split('T')[0]

			const minDateForce: any = new Date();

			if (!this.startDateDiffMeasure) {
				minDateForce.setFullYear(minDateForce.getFullYear() - 1);
			} else {
				minDateForce[`set${startMethod}`](minDateForce[`get${startMethod}`]() - startDiference);
			}

			this.minDateStart = minDateForce.toISOString().split('T')[0];

			//Fecha maxima
			this.maxDateStart = currentStartDateString;
		}
		if (this.configRangeDateStart === 'gt' || this.configRangeDateStart === 'gte') {
			const maxDateForce: any = new Date();

			if (!this.dateDiffMeasure) {
				maxDateForce.setFullYear(maxDateForce.getFullYear() + 1);
			} else {
				maxDateForce[`set${startMethod}`](maxDateForce[`get${startMethod}`]() + startDiference);
			}

			this.maxDateStart = maxDateForce.toISOString().split('T')[0];

			//Fecha minima
			this.minDateStart = currentStartDateString;
		}

		const currentEndDate = new Date();

		if (this.configRangeDateEnd === 'lt') currentEndDate.setDate(currentEndDate.getDate() - 1);

		if (this.configRangeDateEnd === 'gt') currentEndDate.setDate(currentEndDate.getDate() + 1);

		const currentEndDateString = currentEndDate.toISOString().split('T')[0];

		const minDateForce: any = new Date();

		if (!this.endDateDiffMeasure) {
			minDateForce.setFullYear(minDateForce.getFullYear() - 1);
		} else {
			minDateForce[`set${endMethod}`](minDateForce[`get${endMethod}`]() - endDiference);
		}

		this.minDateEnd = minDateForce.toISOString().split('T')[0];

		this.maxDateEnd = currentEndDateString;
	}

	inputRangeDateOnChange(input: 'start' | 'end', $event: any) {
		this.onChange('');
		const entryDate = new Date($event.value);

		const minDateStart = this.minDateStart ? entryDate.getTime() >= new Date(this.minDateStart ?? '').getTime() : true;
		const maxDateStart = this.maxDateStart ? entryDate.getTime() <= new Date(this.maxDateStart ?? '').getTime() : true;

		const minDateEnd = this.minDateEnd ? entryDate.getTime() >= new Date(this.minDateEnd ?? '').getTime() : true;
		const maxDateEnd = this.maxDateEnd ? entryDate.getTime() <= new Date(this.maxDateEnd ?? '').getTime() : true;

		if (input === 'start' && !isNaN(entryDate.getTime()) && minDateStart && maxDateStart) {
			this.inputEndDate.nativeElement.value = '';
			this.minDateEnd = entryDate.toISOString().split('T')[0];
		}
		if (
			input === 'end' &&
			!isNaN(entryDate.getTime()) &&
			minDateEnd &&
			maxDateEnd &&
			this.inputStartDate.nativeElement.value.length === 10
		) {
			let rangeOfDate = `${new Intl.DateTimeFormat('es-Es', {
				timeZone: 'America/caracas',
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date((this.inputStartDate.nativeElement.value as any).replaceAll('-', '/')))} - ${new Intl.DateTimeFormat('es-Es', {
				timeZone: 'America/caracas',
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			}).format(new Date((this.inputEndDate.nativeElement.value as any).replaceAll('-', '/')))}`;

			this.onChange(rangeOfDate);
		}
	}
}
