import { trigger, state, transition, animate, style } from '@angular/animations';

export const vla_toast = trigger('toastInScreem', [
	state(
		'void',
		style({
			position: 'relative',
			bottom: '30px',
			opacity: 0,
		})
	),
	transition(':enter', [animate(300, style({ position: 'relative', bottom: '0px', opacity: 1 }))]),
	transition(':leave', [animate(300, style({ position: 'relative', bottom: '0px', opacity: 0 }))]),
]);
