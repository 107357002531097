import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlRangeComponent } from './vl-range.component';



@NgModule({
  declarations: [
    VlRangeComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    VlRangeComponent
  ]
})
export class VlRangeModule { }
