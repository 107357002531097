import { Component, Input, Output, inject, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { getBase64 } from '@utils/getBase64';
import { vlComponentWithSuscription } from '@utils/vlComponentSuscription';

@Component({
	selector: 'vl-input-upload-img',
	templateUrl: './vl-input-upload-img.component.html',
	styleUrls: ['./vl-input-upload-img.component.scss'],
})
export class VlInputUploadImgComponent extends vlComponentWithSuscription {
	@Input() value: string | File | undefined | null = '';
	@Input() maxSizeFile: number = 0;
	@Input() bgColorBoxImg: string = '#ffffff';
	@Output() onChange: EventEmitter<any> = new EventEmitter();
	public previewURL: string = '';
	private sanatizer: DomSanitizer = inject(DomSanitizer);

	constructor() {
		super();
	}

	async buildPreview($event: any) {
		try {
			const file = $event.target.files[0];
			if (!file) return;

			const { size, type } = file;
			if (size > this.maxSizeFile) {
				$event.target.value = '';
				this.showNotification({
					type: 'error',
					title: 'Exede el peso maximo',
					msg: 'Solo se aceptan imagenes con un peso menor o igual a 25mb',
				});

				return;
			}

			if (!/image\//.test(type)) {
				$event.target.value = '';

				this.showNotification({ type: 'error', title: 'Formato incorrecto', msg: 'Solo se aceptan archivos con formato de imagen' });
				return;
			}

			const infoFile: any = await getBase64(file, this.sanatizer);
			this.previewURL = infoFile.base;
			this.value = file;
			this.onChange.emit(this.value);
		} catch (error) {
			console.log(error);
		}
	}
}
