import { WithThesePermissionDirective } from './with-these-permission.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [WithThesePermissionDirective],
	imports: [CommonModule],
	exports: [WithThesePermissionDirective],
})
export class WithThesePermissionModule {}
