import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
	selector: 'vl-option',
	templateUrl: './vl-option.component.html',
	styleUrls: ['./vl-option.component.scss'],
})
export class VlOptionComponent implements OnChanges {
	@Input() isOpen: boolean = false;
	@Input() coordinates!: {
		x: number;
		y: number;
		width: number;
		height: number;
		top: number;
		right: number;
		bottom: number;
		left: number;
	};
	@Input() config: { type?: 'text' | 'icon'; isMulti?: boolean; options?: Array<any> } = { type: 'text', isMulti: false, options: [] };
	@Input() value: string | Array<string> = '';
	@Output() onClose: EventEmitter<any> = new EventEmitter();
	@Output() onSelectThis: EventEmitter<any> = new EventEmitter();
	@Output() OnScroll: EventEmitter<any> = new EventEmitter();

	optionsSelected: Array<any> = [];
	label: string = '';
	constructor(private cb: ChangeDetectorRef) {}

	ngOnChanges(changes: any): void {
		if (changes.value) {
			this.optionsSelected = [this.value].flat(Infinity);
		}
	}

	actionClick(value: any, event: MouseEvent) {
		if (!this.config.isMulti) return this.selectThis(value);

		event.stopPropagation();
		this.optionsSelected.includes(value) ? this.deselectThis(value) : this.selectThis(value);
		this.onSelectThis.emit(this.optionsSelected.filter((option) => option !== ''));
	}

	selectThis(newValue: any) {
		this.config.isMulti ? (this.optionsSelected = [...this.optionsSelected, newValue]) : (this.optionsSelected[0] = newValue);
	}

	deselectThis(oldValue: any) {
		this.optionsSelected = this.optionsSelected.filter((option: any) => option !== oldValue);
	}

	close() {
		this.onClose.emit(this.optionsSelected.filter((option) => option !== ''));
	}

	checkScroll($event: any) {
		const { scrollTop, clientHeight, scrollHeight } = $event['target'];

		if (scrollHeight - (clientHeight + scrollTop) > 10) return;

		this.OnScroll.emit();
	}
}
