<div class="wrapper-range" [ngStyle]="{ '--fill': value - 1 + '%' }">
	<input
		class="wrapper-range__vl-range"
		type="range"
		min="0"
		[value]="value"
		step="1"
		max="100"
		(input)="limit($event)"
		[ngStyle]="{ '--fill': value + 1 + '%' }"
	/>
</div>
