import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlNotificationComponent } from './vl-notification.component';
import { ToastComponent } from './components/toast/toast.component';
import { VlIconModule } from '@components/vl-icon/vl-icon.module';

@NgModule({
	declarations: [VlNotificationComponent, ToastComponent],
	imports: [CommonModule, VlIconModule],
	exports: [VlNotificationComponent],
})
export class VlNotificationModule {}
