import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	element: any = null;
	config: any = {
		dialog: {
			style: {},
		},
		header: {
			title: 'Eliminar grupo',
			style: {
				'--bg': '#4b27ef',
				'--color': '#fff',
			},
		},
		body: {
			content: [
				{ type: 'text', item: { value: '¿Estás seguro que deseas eliminar el grupo?' } },
				{ type: 'boxDeleteResource', item: { value: 'Headquarter' } },
			],
			style: {},
		},
		footer: {
			btnSucess: {
				available: true,
				text: 'Continuar',
			},
			btnCancel: {
				available: true,
				text: 'Cancelar',
			},
			style: {},
		},
	};
	onChange: BehaviorSubject<any> = new BehaviorSubject({ element: this.element, config: this.config, isOpen: false });
	onClose: EventEmitter<any> = new EventEmitter();
	constructor() {}

	open(element: any, config: any = {}) {
		this.element = element;
		this.config = config;
		this.onChange.next({ element: this.element, config: this.config, isOpen: true });
	}
	changeConfig(config: any = {}) {
		this.config = config;
		this.onChange.next({ ...this.onChange.getValue(), config: this.config });
	}

	close(element = null) {
		this.onChange.next({ ...this.onChange.getValue(), element: null, isOpen: false });
		this.onClose.emit(element);
	}
}
