import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { lastValueFrom } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { WebSocketService } from './web-socket.service';

@Injectable({
	providedIn: 'root',
})
export class LoginService {
	private _apiSrc: ApiService<any> = inject(ApiService);
	private _authSrc: AuthService = inject(AuthService);
	private _webSocketSrc: WebSocketService = inject(WebSocketService);
	private router: Router = inject(Router);
	private reqRefreshToken: boolean = false;

	constructor() {}

	login(email: string, password: string) {
		return this._apiSrc.post('/auth/v2/signIn', { email, password, signIn: 'admin' });
	}
	logout() {
		this._authSrc.clearAll();
		this._webSocketSrc.disconnect();
		this.router.navigate(['/auth']);
		// return this._apiSrc.post('');
	}
	validToken(token: string) {
		return this._apiSrc.post('/auth/v2/vTokenPwr', { token });
	}
	sendMailResetPassword(email: string) {
		return this._apiSrc.post('/auth/v2/resetPwr', { email, url: `${environment.adminUrl}/auth/reset-pass` });
	}
	updatePassword(token: string, password: string) {
		return this._apiSrc.put('/auth/v2/changePwr', { token, password });
	}

	async refreshToken(reload: boolean = true) {
		try {
			if (this.reqRefreshToken) return;
			this.reqRefreshToken = true;
			await lastValueFrom(this._apiSrc.post('/auth/refresh-token'));
			this.reqRefreshToken = false;

			if (reload) window.location.reload();
		} catch (error) {}
	}
}
